@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: unset;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar {
  height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8d928f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2563eb;
}
