.separator {
  position: relative;
  text-align: center;
  font-weight: bold;
}

.separator label {
  background-color: #fff;
  padding: 0 0.4em;
  position: relative;
}

.separator:before {
  content: "";
  border-style: solid;
  border-width: 0 0 1px 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  border-color: rgb(13, 13, 14);
}
